<template>
  <div class="home">
    <div class="left">
      <div class="headers"><img src="../icon/left.png" alt="">
      </div>
      <el-row class="tac" style="font-family: 'Courier New', Courier, monospace;">
        <el-col :span="12">
          <!-- default-active="2" -->
          <el-menu unique-opened :default-active="$route.path" class="el-menu-vertical-demo" background-color="#031327"
            text-color="#9AA1A9" active-text-color="#ffffff">

            <template v-for="(item,index) in list">
            <div :key="index" v-if="(custom || list.length > 0)&& !item.hidden">
              <!-- 无下级标签 -->
              <el-menu-item v-if="item.children && !item.alwaysShow" :index="`/${item.children[0].path || ''}`" @click="go(`/${item.children[0].path}`)">
                  <img v-if="item.children && !item.alwaysShow" style="height: 20px;width: 20px;margin-left: 5px;margin-right: 10px;margin-bottom: 5px;"
                    :src="srcHandle(item.children[0].meta.icon, id == `/${item.children[0].path}`,id)" alt="">
                  <span v-if="item.children" style="font-weight: bold;" :style="id==`/${item.children[0].path}`?'color: white':''">{{removeParentheses(item.children[0].meta.title)}}</span>
              </el-menu-item>
              <!-- 有下级标签 -->
              <el-submenu v-show="item.children && item.alwaysShow" :index="index+''">
                <template slot="title">
                  <img v-if="item.meta" style="height: 20px;width: 20px;margin-left: 5px;margin-right: 10px;margin-bottom: 5px;"
                    :src="srcHandle(item.meta.icon,id == `${item.path}`)" alt="">
                  <span v-if="item.meta" style="font-weight: bold;" :style="id==`${item.path}`?'color: white':''">{{removeParentheses(item.meta.title)}}</span>
                </template>
                <el-menu-item-group v-show="item.alwaysShow">
                  <el-menu-item v-show="!item2.hidden" v-for="(item2,index2) in item.children" :key="index2" :index="`${item.path}/${item2.path}`" @click="go(`${item.path}/${item2.path}`,`${item.path}`)">{{item2.meta.title}}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>

            </div>

            </template>
          </el-menu>
        </el-col>
      </el-row>
    </div>
    <div class="right">
      <div class="view_top">
        <div class="tag_list">
          <el-popover
            placement="bottom-end"
            width="400"
            trigger="hover">
            <div class="message_content">
              <div class="message_haeder flex">
                <div class="rel">
                  消息
                  <div class="message_num" v-if="newNum > 0">{{ newNum > 99?'99+' : newNum }}</div>
                </div>
              </div>
              <div class="message_list">
                <div class="noList" v-if="!msgList.length">
                  <img class="noList_img" src="@/icon/kongbaiye_icon@2x.png" alt="">
                  暂无消息
                </div>
                <div class="message_item flex ai_center" v-for="(item,index) in msgList" :key="index" @click="go(item.link),handRead(index)">
                  <div class="message_item_icon" :class="item.status == 0?'message_item_mark':''">
                    <img class=" " :src="require(`../assets/images/msg_type${item.type}.png`)" alt="">
                  </div>
                  <div class="flex1">
                    <div class="flex ai_center">
                      <div class="message_item_title flex1">{{item.type == 1?'订单消息':'账号审核'}}</div>
                      <div class="message_item_time">{{item.createTime}}</div>
                    </div>
                    <div class="message_item_text line_1">{{item.content}}</div>
                  </div>
                </div>

              </div>
              <div class="message_footer">
                <div class="flex ai_center message_footer_content">
                  <div @click="allRead" class="message_footer_left">全部已读</div>
                  <div class="flex1"></div>
                  <div class="flex ai_center message_footer_right" @click="go('/message')">
                    全部消息
                    <img class="more_icon" src="../assets/images/more.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="message_box" slot="reference">
              <img class="message_icon" src="../assets/images/news.png" alt="">
              <div class="message_num" v-if="newNum > 0">{{ newNum > 99?'99+' : newNum }}</div>
            </div>
          </el-popover>
          
          <el-popover
            placement="bottom-end"
            width="400"
            trigger="hover">
            <el-image slot="reference" class="avatar" fit="cover" :src="user.avatar" :preview-src-list="[user.avatar]">
              <img slot="error" class="avatar" src="../assets/images/avatar.png" alt="">
            </el-image>
            <!-- 弹出层 -->
            <div class="recycler_info">
              <div class="flex ai_center">
                <div class="rel avatar_box">
                  <img class="avatar" :src="user.avatar||require('../assets/images/avatar.png')" alt="">
                  <div class="avatar_box_bg">
                    <img src="../assets/images/img.png" alt="">
                    
                    <el-upload
                      class="upload_avatar"
                      action="https://genqian.top/test/prod-api/gqManage/upload-file-tencent-cos"
                      :multiple="false"
                      :accept="'image/*'"
                      :before-upload="response => checkSize(response)"
                      :on-success="response => handleSuccess(response)">
                      <i slot="default" class="el-icon-plus"></i>
                    </el-upload>
                  </div>
                </div>
                <div class="flex1">
                  <div class="flex ai_center">
                    <div class="user_name flex1">{{ nickName }}</div>

                    <el-rate
                      v-if="limitJudge(signList,'system:role:recycle','')"
                      :value="getScore(recycleInfo.orderStatistics)"
                      disabled
                      text-color="#ff9900"
                      >
                    </el-rate>
                  </div>
                  <div class="phone">{{user.userName}}</div>
                </div>
              </div>
              <div class="">
                <div class="recycler_name">{{user.dept.deptName}}</div>
                <div class="flex ai_center recycler_address">
                  <img src="../assets/images/address.png" alt="">
                  {{recycleInfo.address || address || '暂无地址'}}
                </div>
              </div>
              <div v-if="limitJudge(signList,'system:role:recycle','') && recycleInfo.orderStatistics" class="flex ai_center recycler_data">
                <div class="recycler_data_box">
                  <div class="recycler_data_item">
                    <div class="recycler_data_title">总金额</div>
                    <div class="recycler_data_num">¥{{recycleInfo.orderStatistics.orderTotalAmount || 0}}</div>
                  </div>
                </div>
                <div class="recycler_data_box">
                  <div class="recycler_data_item">
                    <div class="recycler_data_title">总订单</div>
                    <div class="recycler_data_num">{{recycleInfo.orderStatistics.orderTotalNum || 0}}</div>
                  </div>
                </div>
                <div class="recycler_data_box">
                  <div class="recycler_data_item">
                    <div class="recycler_data_title">进行中的订单</div>
                    <div class="recycler_data_num recycler_data_num2">{{recycleInfo.orderStatistics.orderProcessNum || 0}}</div>
                  </div>
                </div>
              </div>
              <div class="mgt-30 flex ai_center jc_end">
                <!-- <div @click="editPswPop = true" class="recycler_info_btn">修改密码</div>
                <div @click="close" class="recycler_info_btn">退出登录</div> -->
                <div v-if="limitJudge(signList,'gqmanage:invoice:list')||limitJudge(signList,'gqmanage:contract:list')" @click="handEditSafe" class="flex1 flex ai_center jc_center fd_c recycler_info_hand">
                  <img class="hand_icon" src="../assets/images/safe_psw.png" alt="">
                  <div class="">修改安全密码</div>
                </div>
                <div @click="editPswPop = true" class="flex1 flex ai_center jc_center fd_c recycler_info_hand">
                  <img class="hand_icon" src="../assets/images/password2.png" alt="">
                  <div class="">修改登录密码</div>
                </div>
                <div @click="close" class="flex1 flex ai_center jc_center fd_c recycler_info_hand">
                  <img class="hand_icon" src="../assets/images/logout.png" alt="">
                  <div class="">退出登录</div>
                </div>
              </div>
            </div>
          </el-popover>
          <!-- <p class="name">{{ nickName }}</p>
          <img class="logout" style="object-fit: cover;" src="../icon/编组@2x.png" alt=""> -->
        </div>
      </div>
      <router-view></router-view>
      <!-- <div class="view_bottom">
        <div class="seize"></div>
        <div class="homes">
          <router-view></router-view>
        </div>
      </div> -->
    </div>
    
    <!-- 修改密码 -->
    <el-dialog class="edit_pop" custom-class="center" title="修改密码" @close="editPswPop = false" :show-close="true" :visible="editPswPop" width="400px">
        <div class="edit_content common_form">
            <el-input 
              class="form_input"
              placeholder="请输入原密码"
              show-password
              v-model="editForm.oldPassword">
              <img slot="prefix" src="@/assets/images/password.png" class="icon" />
            </el-input>
            <el-input 
              class="form_input mgt-20"
              placeholder="请输入密码（不低于6位）"
              show-password
              v-model="editForm.newPassword">
              <img slot="prefix" src="@/assets/images/password.png" class="icon" />
            </el-input>
            <el-input 
              class="form_input mgt-20"
              placeholder="请确认新密码"
              show-password
              v-model="verifyPassword">
              <img slot="prefix" src="@/assets/images/password.png" class="icon" />
            </el-input>
            <el-button @click="editPassword" class="submit_btn" type="primary">确定</el-button>
            <div @click="go('/editPassword')" class="forgot_password">忘记密码？</div>
        </div>
    </el-dialog>
    <!-- 修改安全密码 -->
    <div class="safe_psw_pop" v-if="safePswPop" @click="safePswPop = false">
      <edit-safe-psw :show-close="true" @close="safePswPop = false" ></edit-safe-psw>
    </div>
  </div>
</template>

<script>
import AreaVue from './page/Area.vue'
// import {core} from '../util/core/core'
import webSockets from '@/util/websocket/webSockets'
  import editSafePsw from '@/components/editSafePsw.vue'
import cal from "@/util/channel";
import { Link } from 'element-ui';

export default {
  components:{
      editSafePsw
  },
  data () {
    return {
      custom: false,
      id: '', //路由索引
      nickName: '',  //用户昵称
      list: [], //路由列表
      newNum: 0,  //消息数量
      user: '',  //用户信息
      signList: [],  //权限列表
      recycleInfo: '', //回收商信息
      address: '', //地址
      editPswPop: false, //修改密码
      editForm:{
        oldPassword: '',
        newPassword: '',
      },
      verifyPassword: '',
      msgList: [],
      safePswPop: false,
      isSafeVerify: false
    }
  },
  created () {
    // 客户本部权限
    if (JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("personType")))) == '1') {
      this.custom = false
    } else {
      this.custom = !false
    }
    // this.id = localStorage.getItem('id')
    this.nickName = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("nickName"))))
    this.getRouters()

    this.routeProcess(this.$route.path,'init')
    var that = this
    if(localStorage.getItem("user")){
      this.signList = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("permissions"))))
      const user = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("user"))))
      this.user = user
      console.log(user)
      // socket 接入
      //账号审核相关
      if(this.limitJudge(this.signList, 'system:role:platform')){
        window.socket2 = new webSockets(this.user.deptId,'2');
        window.socket2.websocket.onmessage = (e) => {
          console.log('2')
          that.getMessage(e.data,)
        }
      }
      this.getNotificationList()
      // 订单相关
      // if(this.signList.includes('system:role:recycle')){
        window.socket1 = new webSockets(this.user.deptId,'1');
        window.socket1.websocket.onmessage = function(e){
          console.log('1')
          that.getMessage(e.data)
        }
      // }
      if(this.limitJudge(this.signList,'system:role:recycle','')){
        this.getRecycle()
      }else if(this.limitJudge(this.signList,'system:role:custom','')){
        this.getCompanyAddress()

      }
    }
    // 监听消息页面
    cal.$off('refreshMsg')
    cal.$on('refreshMsg',(res)=>{
      if(res == 'all'){
        this.markersRead()
      }else{
        this.getNotificationList()

      }
    })
    cal.$off('isSafeVerify')
    cal.$on('isSafeVerify',(res)=>{
      this.isSafeVerify = res
    })


  },
  watch:{
    $route(to, from) {
      // console.log("到哪个页面去", to.path);
      this.routeProcess(to.path,'init')
    }
  },
  methods: {
    // 修改安全密码
    handEditSafe(){
      let path = this.$route.path
      if((path == '/billContract/contract' || path == '/billContract/bill') && !this.isSafeVerify) return false
      this.safePswPop = true
    },
    checkSize(file){//上传之前
        const isMaxSize = file.size / 1024 / 1024 < 20;
        if(!isMaxSize){
            this.$message.error('上传图片大小不能超过 20MB!');
        }
        return isMaxSize
    },
    handleSuccess(res,key){
        // console.log(res)
      this.user.avatar = res.msg
      let parm = {
        avatar:this.user.avatar,
        userId: this.user.userId
      }
      this.editAvatar(parm)

    },
    editAvatar(parm){
      this.$api.editAvatar(parm).then(res=>{
        if(res.code == 200){
          // this.userInfo = res.data
          this.$message.success('修改成功')
          localStorage.setItem(
            "user",
            window.btoa(
              window.encodeURIComponent(
                JSON.stringify(this.user)
              )
            )
          );
        }
      })
    },
    // 已读
    handRead(index){
      if(this.msgList[index].status == '1') return false
      let id = this.msgList[index].id
      if(!id) return false
        let parm = {
            ids: id
        }
        this.$api.notificationRead(parm).then(res=>{
            this.msgList[index].status = '1'
            // this.$message.success('操作成功')

        }).catch(res=>{})
        
    },
    // 全部已读
    allRead(){
        this.$api.allRead().then(res=>{
            this.$message.success('操作成功')
            this.markersRead()
            cal.$emit('allRead')
        }).catch(res=>{})
    },
    // 标记已读
    markersRead(){
        this.msgList.forEach((item,index)=>{
          item.status = '1'
        })
        this.newNum = 0
    },
    // 获取未读消息列表
    getNotificationList(){
        let parm = {
            type: '',
            status: 0
        }
        this.$api.getNotificationList(parm).then(res => {
            this.msgList = res.rows
            this.newNum = res.total
        }).catch(res=>{})
    },
    // 修改密码
    editPassword(){
      let parm = {
        ...this.editForm
      }
      if(!parm.oldPassword){
        this.$message.error('请输入旧密码')
        return false
      }
      if(!parm.newPassword){
        this.$message.error('请输入新密码')
        return false
      }
      if(!this.verifyPassword){
        this.$message.error('请再次确认新密码')
        return false
      }
      if(parm.newPassword != this.verifyPassword){
        this.$message.error('两次密码不一致')
        return false
      }
      if(parm.oldPassword == parm.newPassword){
        this.$message.error('新旧密码不能一样')
        return false
      }
      this.$api.editPassword(parm).then(res=>{
        this.$message.success('修改成功')
        this.close()
      }).catch(res=>{
      })
    },
    // 获取回收商信息
    getRecycle(){
        this.$api.getRecycle({deptId: this.user.deptId}).then(res=>{
          this.recycleInfo = res.data
        }).catch(res=>{})
    },
    // 获取公司地址
    getCompanyAddress(){
        this.$api.getCompanyAddress({deptId: this.user.deptId}).then(res=>{
          this.address = res.msg
        }).catch(res=>{})

    },
    removeParentheses(str) {
      // 使用正则表达式匹配括号及其内部内容，并替换为空字符串
      return str.replace(/\([^()]*\)/g, '');
    },
    getMessage(data){
      let info = JSON.parse(data)
      info.createTime = this.getDate(info.createTime)
      this.newNum++
      this.msgList.unshift(info)
      cal.$emit("newMessage", info);
    },
    routeProcess(path,init){
      // 获取当前页面路由 根据路由给id赋值
      let pathLength = (path.match(/\//g) || []).length
      let menuId = path
      if(pathLength > 1){
        let index =  path.indexOf('/', path.indexOf('/') + 1)
        menuId = path.substring(0, index)
      }
      if(init){
        this.id = menuId
      }else{
        return menuId
      }

    },
    srcHandle(name,on,id){
      return require(`../assets/images/${name}${on?'_on':''}.png`)
    },
    async getRouters(){
      try{
        
        let res = await this.$api.getRouters()
        if(res.data.length < 1 || !res.data){
          
          this.$message({
            type: "warning",
            message: "未分配权限，请联系管理员!",
          });
          setTimeout(()=>{
            this.close()
          },1500)
          return false
        }
        let list =  res.data
        this.list = list

        // this.$router.beforeEach((to, from, next) => {
        //   // 在每次路由切换前执行的逻辑
        //   console.log(to, from)
        //   next()
        // })
        let path
        // 取目录的第一个路由
        // 判断是否有子页面
        if(list[0].children && !list[0].alwaysShow){
          path = `/${list[0].children[0].path}`
        }else if(list[0].children && list[0].alwaysShow){
          path = `${list[0].path}/${list[0].children[0].path}`

        }
        // 判断当前页面是否在用户动态路由中
        let path_on = this.routeProcess(this.$route.path)
        if(path_on === '/HomeView') this.$router.push(path)
        if(list.includes('/recyclerOrder')){

        }
      }
      catch{

      }
    },
    go(url,id,open = true){
      if(!open) return false
      localStorage.setItem('id', url)
      // this.id = id || url
      this.$router.push(url)
    },
   
    close () {
      this.$api.logout().then(res=>{
        // localStorage.clear();
        // this.$router.push('/page')
        this.$logout()
      }).catch(res=>{})
    }
  }
}
</script>

<style lang="scss" scoped>
*{
  box-sizing: border-box;
}
.el-col-12 {
  width: 100% !important;
}
.headers {
  height: 80px;
  width: 100%;
  position: relative;
  // background-color: #e6e6e6;
}
.headers img {
  position: absolute;
  top: 30px;
  left: 28px;
  height: 32px;
  width: 94px;
}
.home {
  width: 100%;
  height: 100vh;
  min-width: 1400px;
  min-height: 800px;
  background-color: #031327;
  display: flex;
}
.left {
  height: 100vh;
  width: 200px;
  min-height: 800px;
  /* width: calc(100% - 80px); */
  background-color: #031327;
  // float: left;
}
.el-submenu .el-menu-item {
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0px 73px;
  min-width: 200px;
}
.right {
  height: 100vh;
  min-height: 800px;
  width: calc(100% - 200px);
  // float: left;
  min-width: calc(100% - 200px);
  /* 颜色 */
  background-color: #f6f6f6;
}
.view_top {
  height: 50px;
  width: 100%;
  background-color: #ffffff;
  min-width: 1200px;
  cursor: pointer;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.06);
  position: relative;
  z-index: 99;
}
.tag_list {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 0 0;
}
.tag_list .avatar {
  height: 35px;
  width: 35px;
  border-radius: 35px;
  object-fit: cover;
}
.tag_list .name {
  line-height: 50px;
  font-weight: bold;
  text-align: left;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* background-color: #a17e7e9f; */
  max-width: 48px;
  margin-left: 8px;
}
.tag_list .logout {
  /* line-height: 41px; */
  /* font-weight: bold; */
  /* background-color: aqua; */
  text-align: left;
  font-size: 20px;
  width: 18px;
  height: 18px;
  margin-left: 20px;
}
/* 空间 */
.view_bottom {
  height: calc(100vh - 50px);
  width: 100%;
  min-width: 1200px;
  min-height: 750px;
  /* background-color: #2e0303; */
}
/* / */
.el-menu {
  border-right: solid 0px #e6e6e6 !important;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  background-color: #fff;
}
/* 占位 */
.seize {
  height: 15px;
  width: 100%;
}
.homes {
  height: calc(100vh - 80px);
  width: 98%;
  min-width: 1170px;
  min-height: 720px;
  border-radius: 5px;
  background-color: white;
  margin: auto;
}
.homes p {
  height: 40px;
  width: 40px;
  background-color: blue;
}
.el-menu-item.is-active {
  color: #fe793bff;
  // border-left: 4px solid #217dff;
  background: rgb(255 255 255 / 4%) !important;
  font-weight: 800;
}
::v-deep .el-menu-item-group__title {
  padding: 0px 0 0px 20px !important;
  line-height: normal;
  font-size: 12px;
  color: #909399;
}

.message_box{
  margin-right: 40px;
  position: relative;
  .message_icon{
    height: 20px;
  }

}
.message_num{
  min-width: 16px;
  line-height: 16px;
  background: #F84A44;
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  color: #FFFFFF;
  position: absolute;
  border-radius:8px;
  top: 0;
  right: 0;
  transform: translate(60%,-60%);
  padding: 0 4px;
}
.message_content{//样式穿透不生效只能给子级加margin
  margin: -12px;
}
.message_haeder{
  padding: 20px 20px 10px;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;;
  border-bottom: 1px solid #eee;
  .message_num{
    transform: translate(100%,-50%);

  }
}
.message_list{
  padding: 10px 0;
  height: 300px;
  overflow: auto;
  .message_item{
    cursor: pointer;
    padding: 10px 20px;
    transition: .3s;
    &:hover{
      background: rgba($color: #666, $alpha: 0.1);
    }
    .message_item_icon{
      margin-right: 8px;
      position: relative;
      img{
        width: 40px;
        height: 40px;

      }
    }
    .message_item_mark::before{
      content: '';
      width: 6px;
      height: 6px;
      background: #F84A44;
      border-radius: 50%;
      position: absolute;
      right: 0px;
      top: 0px;
      display: block;
    }
    .message_item_title{
      font-weight: bold;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
    .message_item_time{
      font-size: 10px;
      color: #999999;
      line-height: 14px;
    }
    .message_item_text{
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 17px;
      margin-top: 2px;

    }
  }
}
.message_footer{
  padding: 0 20px;
  .message_footer_content{
    width: 100%;
    border-top: 1px solid #eeeeee;
    padding: 15px 0;
    .message_footer_left{
      font-weight: bold;
      font-size: 12px;
      color: #016EF1;
      line-height: 17px;
      cursor: pointer;
    }
    .message_footer_right{
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      cursor: pointer;
      .more_icon{
        height: 10px;
        margin-left: 4px;
      }
    }

  }

}
.grade_star{
    img{
        width: 12px;
        margin-right: 8px;
    }
}
.recycler_info{
  padding: 20px;
  margin: -12px;

  .avatar_box{
    overflow: hidden;
    border-radius: 50%;
    margin-right: 8px;
    .avatar{
      width: 40px;
      height: 40px;
      display: block;
      object-fit: cover;
    }
    .avatar_box_bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: .3);
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-100%);
      transition: .3s;
      cursor: pointer;
      img{
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
    }
    &:hover{
      .avatar_box_bg{
        transform: translateY(0);
      }
    }

  }
  .user_name{
    font-weight: bold;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
  }
  .phone{
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    margin-top: 2px;
  }
  .recycler_name{
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    margin-top: 15px;
  }
  .recycler_address{
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    line-height: 17px;
    margin-top: 8px;
    img{
      width: 12px;
      margin-right: 8px;
    }
  }
  .recycler_data{
    margin: 20px -6px 0;
    .recycler_data_box{
      width: 33.33%;
      padding: 0 6px;
      .recycler_data_item{
        width: 100%;
        background: #F8F8F8;
        border-radius: 5px;
        border: 1px solid #F2F2F2;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .recycler_data_title{
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          line-height: 17px;
        }
        .recycler_data_num{
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          line-height: 17px;
          margin-top: 8px;
        }
        .recycler_data_num2{
          color: #FF891F;
        }
      }
    }
  }
  .recycler_info_btn{
    box-sizing: border-box;
    min-width: 80px;
    line-height: 28px;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #EAEAEA;
    padding: 0 10px;
    margin-left: 5px;
    font-weight: bold;
    font-size: 12px;
    color: #333333;
    text-align: center;
    cursor: pointer;
  }
  .recycler_info_hand{
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 1;
    cursor: pointer;
    .hand_icon{
      width: 18px;
      height: 18px;
      margin-bottom: 10px;
    }
  }
}

::v-deep .form_input{
  .icon{
    width: 16px;
    height: 16px;
    position: relative;
    top: 50%;
    transform: translate(0,-50%);
  }
  .el-input__prefix{
    left: 15px;
    transform: translateX(-50%);
  }
}
.submit_btn{
  background: #016EF1;
  width: 100%;
  margin: 20px 0 0;
}
.forgot_password{
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.noList{
  padding: 60px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #999;
  .noList_img{
    width: 150px;
    margin-bottom: 10px;
  }
}
::v-deep .upload_avatar{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  .el-upload{
    width: 100%;
    height: 100%;
  }
}
    .safe_psw_pop{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,.5);
        z-index: 999;
    }
</style>
